import React from 'react';
import { connect } from 'react-redux';
import { Button, Text, Title, Image, Block } from '@homeexchange/design';
import i18n from '../../i18n';
var Error404 = function (_a) {
    var user = _a.user;
    return (React.createElement("div", { className: "page-404" },
        React.createElement(Block, { className: "content" },
            React.createElement("section", { className: "image-container" },
                React.createElement(Image, { src: (user === null || user === void 0 ? void 0 : user.isCollection())
                        ? 'https://gtg-prod.s3.eu-west-1.amazonaws.com/images/website/error/404_he_collection.svg'
                        : 'https://gtg-prod.s3.eu-west-1.amazonaws.com/images/website/error/404_he_regular.svg', alt: "error 404" })),
            React.createElement("section", { className: "text-container" },
                React.createElement(Title, { level: 2 },
                    i18n.t('global:page.404.title'),
                    " "),
                React.createElement(Text, { className: "text-container-paragraph" }, i18n.t('global:page.404.body')),
                React.createElement(Button, { className: "text-container-btn", onClick: function () { return (window.location.href = '/'); } }, i18n.t('global:global.backHomepage'))))));
};
var mapStateToProps = function (state) { return ({
    user: state.user
}); };
export default connect(mapStateToProps)(Error404);
