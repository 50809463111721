import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '../store';
import Error404 from '../components/error/Error-404';

const error = document.getElementById('error-404');

if (error) {
    createRoot(error).render(
        <Provider store={store}>
            <Error404 />
        </Provider>,
        error
    );
}
